<template>
<div>
  <a-parameter-select
      @input="$store.state.finemailer.elementEditTrigger++"
      v-model="$store.state.finemailer.selectedElement.config.font_style"
      :items="headingStylesSelect"
      label="Size"
      :label-color="wsDARKER"
      :value-color="wsACCENT"
      null-text="Default"
      light
  />
  <v-divider class="mt-3 mb-6" :style="`border-color : ${wsDARKLIGHT}; `" />


</div>
</template>

<script>
export default {
  name: "emailSettingsText",
  computed : {
    headingStylesSelect() {
      return [
        { text : this.$t('avalon.color_styles.h1') , value : 'h1' },
        { text : this.$t('avalon.color_styles.h2') , value : 'h2' },
        { text : this.$t('avalon.color_styles.h3') , value : 'h3' },
        { text : this.$t('avalon.color_styles.h4') , value : 'h4' },
        { text : this.$t('avalon.color_styles.h5') , value : 'h5' },
        { text : this.$t('avalon.color_styles.p') , value : 'p' },
        { text : this.$t('avalon.color_styles.h6') , value : 'h6' },
      ]
    },

  }
}
</script>

<style scoped>

</style>